body{
  background-color: #DDC4BB;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 1vmin;
  pointer-events: none;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/*Spinning animation
*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/


.App-header {
  background-color: #ffffff;
  min-height: .5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 20px;
  resize: both;
}

/*Remove extra whitespace between header and content div based on h1 tag*/
h1 {
  margin: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Section-layout {
  min-height: 69vh;
  text-align: center;
  overflow: auto;
  display: block;
  position: relative;
}

/*.ideal-taytay-speak {
  font-family: 'Kalam', cursive;
  font-size: 80px;
}*/

.home-tagline {
  margin: auto;
  width: 85%;
  padding: 10px;
}

.home-event-headline {
  margin: auto;
  width: 80%;
  padding: 10px;
  color: #e1f40e;
}

.home-section {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
  url('./images/TaylorInRed.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  color: #FFFFFF;
  overflow: hidden;
  display: block;
}

.home-section-content {
  color:#ffffff;
  margin: auto;
  width: 50%;
  padding: 10px;
}

.home-section-content-left {
  /*background-color: #61dafb;*/
  color: #fffb05;
  outline-style: solid;
  border-radius: 5px;
  margin: auto;
  width: 90%;
  padding: 10px;
}

.home-section-content-right {
  color: #fffb05;
  outline-style: solid;
  border-radius: 5px;
  padding: 10px;
}

.donor-news {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 120vh;
  overflow: hidden;
  display: block;
  position: relative;
}  

.donor-news-content {
  width: 95%;
}


/*For progressbar begin*/
.bar-light-grey,.bar-hover-light-grey:hover,.bar-light-gray,.bar-hover-light-gray:hover{
  height: 50px!important;
  color:#000!important;
  background-color:#f1f1f1!important
}

.bar-progress {
  width: 50%;
}

.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-red,.w3-hover-red:hover{width:39%!important;height:50px!important;color:#000!important;background-color:#f44336!important}
.w3-orange,.w3-hover-orange:hover{width:26%!important;height:50px!important;color:#000!important;background-color:#ff9800!important}
.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
/*end*/

.news-section {
  width: 100%;
  height: 100%;
  background-color: #FED0D9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 120vh;
  overflow: hidden;
  display: block;
  position: relative;
}

.home-news-vid {
  background-repeat: no-repeat;
  background-position: center;
  min-height: 120vh;
  overflow: hidden;
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(224, 226, 237, 0.52), rgba(205, 8, 80, 0.73));
}

.vid {
  margin: auto;
  width: 50%;
  padding: 100px;
}

.about-section {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(162, 167, 196, 0.52), rgba(117, 19, 53, 0.73)),
  url('./images/taylor_at_the_mic.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  color: #fffb05;
  overflow: hidden;
  display: block;
}

.donate-section {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(162, 167, 196, 0.52), rgba(61, 190, 226, 0.591)),
  url('./images/taylor_river_rafting.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  /*color: #95ff00;*/
  overflow: hidden;
  display: block;
}

.donor-relation-section {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(162, 167, 196, 0.52), rgba(61, 226, 97, 0.591)),
  url('./images/taylor_happyface_cup.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  color: #ffffff;
  overflow: hidden;
  display: block;
}

.donor-relation-section-content {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.tdtboard-load {
  margin: auto;
  width: 50%;
}

.tdtboard {
  margin-top: 30px;
  width: 100%;
}


.article-maker-column {
  flex: 50%;
  padding: 5px;
  text-align: left;
}

.article-maker-column-pic {
  flex: 50%;
  padding: 5px;
}

.article-maker-row{
  display: flex;
}

.article-maker-content {
  width: 50%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  overflow: hidden;
  display: block;
}

.section-content-reg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  overflow: hidden;
  display: block;
}

/*.about-content{

}*/

.contact-form-dress {
  color: #ee00ff;
  text-decoration: none;
}

.footer-art {
  background-color: #282c34;
  color: peru;
  min-height: 9.5vh;
}

.footer-anchor-dress {
  color: peru;
  text-decoration: none;
}

.footer-link-row {
  display: flex;
  
}

.footer-link-column {
  flex: 50%;
  padding: 35px;
  text-align: left;
}

.footer-link-infocolumn {
  flex: 50%;
  padding: 10px;
  text-align: left;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/***************** Mobile ******************/

/* Media query for small devices (phones) */
@media (max-width: 480px) {
  body{
    background-color: #DDC4BB;
    text-align: center;
  }
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 1vmin;
    pointer-events: none;
  }
  
  .App-logo {
    height: 20vmin;
    pointer-events: none;
  }
  
  /*Spinning animation
  *@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }*/
  
  
  .App-header {
    background-color: #ffffff;
    min-height: .5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 20px;
    resize: both;
  }
  
  /*Remove extra whitespace between header and content div based on h1 tag*/
  h1 {
    margin: 0;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .Section-layout {
    min-height: 69vh;
    text-align: center;
    overflow: auto;
    display: block;
    position: relative;
  }
  
  /*.ideal-taytay-speak {
    font-family: 'Kalam', cursive;
    font-size: 80px;
  }*/
  
  .home-tagline {
    margin: auto;
    width: 85%;
    padding: 10px;
  }
  
  .home-event-headline {
    margin: auto;
    width: 80%;
    padding: 10px;
    color: #e1f40e;
  }
  
  .home-section {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
    url('./images/TaylorInRed.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 70vh;
    color: #FFFFFF;
    overflow: hidden;
    display: block;
  }
  
  .home-section-content {
    color:#ffffff;
    margin: auto;
    width: 85%;
    padding: 10px;
  }
  
  .home-section-content-left {
    /*background-color: #61dafb;*/
    color: #fffb05;
    outline-style: solid;
    border-radius: 5px;
    padding: 10px;
  }
  
  .home-section-content-right {
    color: #fffb05;
    outline-style: solid;
    border-radius: 5px;
    padding: 10px;
  }

  .donor-news {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 120vh;
    overflow: hidden;
    display: block;
    position: relative;
  }

  .donor-news-content {
    width: 95%;
  }
  
  /*For progressbar begin*/
  .bar-light-grey,.bar-hover-light-grey:hover,.bar-light-gray,.bar-hover-light-gray:hover{
    height: 50px!important;
    color:#000!important;
    background-color:#f1f1f1!important
  }
  
  .bar-progress {
    width: 50%;
  }
  
  .w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
  .w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
  .w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
  .w3-red,.w3-hover-red:hover{width:47%!important;height:50px!important;color:#000!important;background-color:#f44336!important}
  .w3-orange,.w3-hover-orange:hover{width:26%!important;height:50px!important;color:#000!important;background-color:#ff9800!important}
  .w3-center .w3-bar{display:inline-block;width:auto}
  .w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
  /*end*/
  
  .news-section {
    width: 100%;
    height: 100%;
    background-color: #FED0D9;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 120vh;
    overflow: hidden;
    display: block;
    position: relative;
  }
  
  .home-news-vid {
    background-repeat: no-repeat;
    background-position: center;
    min-height: 120vh;
    overflow: hidden;
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(224, 226, 237, 0.52), rgba(205, 8, 80, 0.73));
  }
  
  .vid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .about-section {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(162, 167, 196, 0.52), rgba(117, 19, 53, 0.73)),
    url('./images/taylor_at_the_mic.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 70vh;
    color: #fffb05;
    overflow: hidden;
    display: block;
  }
  
  .donate-section {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(162, 167, 196, 0.52), rgba(61, 190, 226, 0.591)),
    url('./images/taylor_river_rafting.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 70vh;
    /*color: #95ff00;*/
    overflow: hidden;
    display: block;
  }
  
  .donor-relation-section {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(162, 167, 196, 0.52), rgba(61, 226, 97, 0.591)),
    url('./images/taylor_happyface_cup.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 70vh;
    color: #ffffff;
    overflow: hidden;
    display: block;
  }
  
  .donor-relation-section-content {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
  
  .tdtboard-load {
    margin: auto;
    width: 50%;
  }
  
  .tdtboard {
    margin-top: 30px;
    width: 100%;
  }
  
  
  .article-maker-column {
    flex: 50%;
    padding: 5px;
    text-align: left;
  }
  
  .article-maker-column-pic {
    flex: 50%;
    padding: 5px;
  }
  
  .article-maker-row{
    display: flex;
  }
  
  .article-maker-content {
    width:92%;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow: hidden;
    display: block;
    width: 85%;
    height: 130%;
  }
  
  .section-content-reg {
    position: absolute;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    overflow: hidden;
    display: block;
    width: 90%;
    height: 156%;
  }
  
  /*.about-content{
  
  }*/
  
  .contact-form-dress {
    color: #ee00ff;
    text-decoration: none;
  }
  
  .footer-art {
    background-color: #282c34;
    color: peru;
    min-height: 9.5vh;
  }
  
  .footer-anchor-dress {
    color: peru;
    text-decoration: none;
  }
  
  .footer-link-row {
    display: flex;
    
  }
  
  .footer-link-column {
    flex: 50%;
    padding: 35px;
    text-align: left;
  }
  
  .footer-link-infocolumn {
    flex: 50%;
    padding: 10px;
    text-align: left;
  }
  
  .footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Media query for medium devices (tablets) */
/*@media (min-width: 601px) and (max-width: 1024px) {
  .Section-layout {*/
    /* Tablet-specific styles here */
  /*}
}*/